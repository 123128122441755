import { createMachine } from "xstate";

const StateMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QBsCWUAWAXAsgQwGMNUA7MAOlNS1T2QH0A3VMAd3oHsAzLgYipp0mLVgG0ADAF1EoAA4dY1VBxIyQAD0QBaAIwBmAOzkAHHoBMAFgNmAbDvEBWAJwHjAGhABPbWYd7yOn42FsZmLsbidjoAvtEeaJi4hMRklCRKQsxsnCS8eLKy9FiesmDCbBLSSCDyijQqapoIZnrG5Hp6Tno2xgYu4gZ2eh7eCFpmOkauzubijg7GNi6x8ejY+ESkFAK0DFnsKrysYABGdWXFpeViUmq1Sg3VTS1tHV09fQYDQyOIOsY6chzBw2BYOQY6f42FYgBLrZJbNIZPYiHL8WCwACucCKJTK+0qdwUD1UT0QL3anW6vX6EOGXnJxgs5Cc4kCXVcTkCAxhcKSm1SO0yqMOsA4AFswFgUlB6GBkLAwITqvd6qTQM9WpT3jSvnTfmNIf5jKz9DYDDorHoLP9eWt+SkKPlCpd8SLckKURVbiriWrGuSueRgjZguImUzLJEDeMluQDB0TU47BNxHoYnFYfaNo7yM7cVd9miTpjFGQMQW3d6qnI-cp1RpEMEjBY2Sa9A4QeILD0YzoevGHLZHBYXF1WwY7Ykc4j867rmjZAAnDgAKzABCwleuytrdXrAYQzfIrf+XU7Nm7vYZYzM1mDIQ7-374ijDin8IFFGOZ2oFzxC6HJ6O4+nuJKHmEgIhg4o5JnMBgWAaOhhEC4LTDaerdB+DqIj+5zbkWhwlmWcCwARIi7jUdaPBqTZWCebbnl2PbuDe+jiPGlitiEl5zFYk6ZnyM6pHhf7kdkhzLmuG5bvOBKgVR+40Y2R70ae7YXlerGjP8ZjBtYhiWKGQ7hthwkUMRWwVnJ7r8OkghejcNaKeBZIIHqJgWBYCz2C4YIGkYCytGyQwAp2lhmQiqSWeWZE2RJuRzgB8nOaqB5uT0en-I4eiRF0d6jjGZhmBxXzdq4oJLH0PKCdmUUWaWVlxcltlSeum7iU5RJKQ2TT2AYDjkCCzjgt5jgRA4BoguQJV3os9g2LYhgCas071ZQGLYs1ha2cBKXda5tHNEGIZhhGFhRjYBotHpTh+NYkS5fY4KRV+G1Yji8UHLkMWkZ1lFpcpTTHupTGXix13WsGJXWvBuXUtCtVrW9qCbZ9LUJbwbUyf9CmA71dEtoxHbMdeoxmIsQ09Llgx9OFTivbm2MdV9aJ7RRePUQTCBGjYQLhsEo5OBM-wGAarZtN2xVeb4rIDe+SOfkzK7tbJGPfbwv3WerANc4e-WDcNd0IQ442OOLM0wQCTJOC4-EU4ziJipK0qkLK8qKmz9m7CBqV625kEPqGEshBdrZXTeuigiYwXiE4vTLVyDMwiQHAQHAahCet7MSTwB3+m54zCyYFowfHkxfBeRVDsGoahgt3YJxYjuCt7woJfn6VHboATwRYHbUvYjgJtXel1zYrTmOmHRmC3ToFJ1OSd0DiAy-GSyRJCBUjpNkfDiykxck+ThWMhc-kKJWD-jtHe+j1h4Xa8rg2sYJqjghEc6YYJeGJ2gxMpeB2iscLRUarFReyl8aHmMDBIagwOxci8voTsSEOLgnDN0AYPF44IXPqjD620qzfWXtzfugIwon3rvdEE10bTr2TKycuo4WgrSzMjZW0kWbqyXnfQ6KlkLFwQlLeGiwlhmANJeYM-YvJ12QmEUywDzLkGdlKGUcoFRlEgf7I6j92jP3+G-O2n9tCBCMK+GGd1iqdF8IjVaSstgkMPFoWGJdAiwQrvMYxYxuhQUhIsBMlhEzJ1iEAA */
  createMachine({
    id: "lightMachine",
    predictableActionArguments: true,
    initial: "initial_view_on",
    states: {
      initial_view_off: {
        on: {
          initial_view: {
            target: "initial_view_on",
          },
        },
      },

      initial_view_on: {
        on: {
          app_type_view: {
            target: "app_type_view_on",
          },
          website_type_view: {
            target: "website_type_view_on",
          },
          issues_type_view: {
            target: "issues_type_view_on",
          },
          contact_form_something_else: {
            target: "contact_form_something_else_on",
          },
        },
      },

      app_type_view_on: {
        on: {
          initial_view: {
            target: "initial_view_on",
          },
          business_type_view: {
            target: "business_type_view_on",
          },
          project_type_view: {
            target: "project_type_view_on",
          },
        },
      },

      website_type_view_on: {
        on: {
          initial_view: {
            target: "initial_view_on",
          },
          business_type_view: {
            target: "business_type_view_on",
          },
          project_type_view: {
            target: "project_type_view_on",
          },
        },
      },

      business_type_view_on: {
        on: {
          initial_view: {
            target: "initial_view_on",
          },
          app_type_view: {
            target: "app_type_view_on",
          },
          website_type_view: {
            target: "website_type_view_on",
          },
          issues_type_view: {
            target: "issues_type_view_on",
          },
          project_type_view: {
            target: "project_type_view_on",
          },
        },
      },

      issues_type_view_on: {
        on: {
          initial_view: {
            target: "initial_view_on",
          },
          business_type_view: {
            target: "business_type_view_on",
          },
          project_type_view: {
            target: "project_type_view_on",
          },
        },
      },

      project_type_view_on: {
        on: {
          initial_view: {
            target: "initial_view_on",
          },
          business_type_view: {
            target: "business_type_view_on",
          },
          website_type_view: {
            target: "website_type_view_on",
          },
          app_type_view: {
            target: "app_type_view_on",
          },
          contact_form_view: {
            target: "contact_form_view_on",
          },
        },
      },

      contact_form_view_on: {
        on: {
          project_type_view: {
            target: "project_type_view_on",
          },
          initial_view: {
            target: "initial_view_on",
          },
        },
      },

      contact_form_something_else_on: {
        on: {
          initial_view: {
            target: "initial_view_on",
          },
        },
      },
    },
  });
export default StateMachine;
