import React, { useState, createContext, useMemo } from "react";

import { useInterpret } from "@xstate/react";
import { InterpreterFrom } from "xstate";

import StateMachine from "../../quote/xstate-machine/state-machine";
import { ChildrenProps, ITrackingTitle, xMachineProps } from "../interfaces/website.interface";

export const ContextWrapper = createContext<xMachineProps>({
  pageTracked: false,
  setPageTracked: () => {},
  captchaToken: "",
  setCaptchaToken: () => {},
  accordionExpanded: false,
  setAccordionExpanded: () => {},
  activeStep: 0,
  setActiveStep: () => {},
  hoveredQuoteIcon: "",
  setHoveredQuoteIcon: () => {},
  selectedAnswer: "",
  setSelectedAnswer: () => {},
  userResponses: [],
  setUserResponses: () => {},
  authService: {} as InterpreterFrom<typeof StateMachine>,
  trackingTitle: { isWebsite: false, isApp: false, isIssues: false },
  setTrackingTitle: () => {},
});

const Context = (children: ChildrenProps) => {
  const authService = useInterpret(StateMachine);
  const [selectedAnswer, setSelectedAnswer] = useState<string>("");
  const [userResponses, setUserResponses] = useState<string[]>([]);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [hoveredQuoteIcon, setHoveredQuoteIcon] = useState<string>("");
  const [captchaToken, setCaptchaToken] = useState<string>("");
  const [pageTracked, setPageTracked] = useState<boolean>(false);
  const [accordionExpanded, setAccordionExpanded] = useState<string | false>("panel-1");
  const [trackingTitle, setTrackingTitle] = useState<ITrackingTitle>({
    isWebsite: false,
    isApp: false,
    isIssues: false,
  });

  const values: xMachineProps = useMemo(
    () => ({
      pageTracked,
      setPageTracked,
      captchaToken,
      setCaptchaToken,
      accordionExpanded,
      setAccordionExpanded,
      activeStep,
      setActiveStep,
      hoveredQuoteIcon,
      setHoveredQuoteIcon,
      selectedAnswer,
      setSelectedAnswer,
      userResponses,
      setUserResponses,
      authService,
      trackingTitle,
      setTrackingTitle,
    }),
    [
      accordionExpanded,
      activeStep,
      authService,
      captchaToken,
      hoveredQuoteIcon,
      pageTracked,
      selectedAnswer,
      trackingTitle,
      userResponses,
    ],
  );

  return <ContextWrapper.Provider value={values} {...children} />;
};

export default Context;
